import { removeDecimal, removeNonNumber, removeLeadingZero } from './numberHelper';

function sanitizeIDRCurrencyValue(value) {
    if (!value || isNaN(value)) {
        return 0;
    }

    if (!value && value.toString().length > 1) {
        return 0;
    }

    let sanitizedValue = removeNonNumber(removeLeadingZero(value));

    if (!sanitizedValue) {
        sanitizedValue = 0;
    }

    return removeDecimal(sanitizedValue);
}
window.sanitizeIDRCurrencyValue = sanitizeIDRCurrencyValue;

function sanitizeVolumeValue(value) {
    if (!value || isNaN(value)) {
        return 0;
    }

    let sanitizedValue = removeNonNumber(value.toString());

    if (sanitizedValue.charAt(sanitizedValue.length - 1) === '.') {
        return sanitizedValue;
    }

    sanitizedValue = removeLeadingZero(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    return parseFloat(sanitizedValue);
}
window.sanitizeVolumeValue = sanitizeVolumeValue;
